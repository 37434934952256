import React from "react";

const Home: React.FC = () => (
  <div className="flex flex-col gap-10 items-center justify-center h-screen bg-gray-100 p-4">
    <h1 className="text-0">
      HELLO
      <img className="h-16" src="./logo.svg" alt="Hello Logo" />
    </h1>
    <div className="flex sm:flex-row flex-col gap-5 items-center justify-center">
      <a
        href='https://apps.apple.com/us/app/hello-talk-chat-meet/id1079611662'
        target='_blank'
        rel="noreferrer"
        title="Download Hello for iOS"
        className="text-0"
      >
        Download Hello for iOS
        <img className="h-14" src="./app-store.svg" alt="Download on App Store" />
      </a>
      <a
        href='https://play.google.com/store/apps/details?id=net.helloapp.hello&hl=en'
        target='_blank'
        rel="noreferrer"
        title="Download Hello for Android"
        className="text-0"
      >
        Download Hello for Android
        <img className="h-14" src="./play-store.svg" alt="Download on Play Store" />
      </a>
    </div>
  </div>
);

export default Home;