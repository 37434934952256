import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const DeleteAccount: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null); // Store reCAPTCHA token

  const createUserSession = async (captchaToken: string): Promise<string | null> => {
    try {
      const response = await fetch('https://helloapi.helloapp.chat/v4/auth/create-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: username,
          password: password,
          captcha: captchaToken
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.token;
      } else {
        setStatusMessage('Incorrect username or password.');
        return null;
      }
    } catch (error) {
      setStatusMessage('Unknown error while authenticating.');
      return null;
    }
  };

  const deleteAccount = async (token: string) => {
    try {
      const response = await fetch('https://helloapi.helloapp.chat/v4/account/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          username: username,
          password: password,
          captcha: captchaToken
        }),
      });

      if (response.ok) {
        setStatusMessage('Account deleted successfully!');


      } else if (response.status === 401) {
        setStatusMessage('Unauthorized. Invalid token or session.');
      } else {
        setStatusMessage('Failed to delete account. Please try again.');
      }
    } catch (error) {
      setStatusMessage('Unknown error while deleting account.');
    }
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!captchaToken) {
      setStatusMessage('Please complete the CAPTCHA.');
      return;
    }

    const userToken = await createUserSession(captchaToken);

    if (userToken) {
      await deleteAccount(userToken);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 p-4">
      <div className="w-full max-w-sm">
        <div className='text-center mb-10'>
          <h1 className='text-4xl font-semibold mb-2 text-gray-800'>Delete Account</h1>
          <p className='mb-1 text-gray-500'>To delete your <b>Hello</b> account, please confirm it with your credentials.</p>
          <p className='text-red-600'>Account deletion is not reversible.</p>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-lg shadow-gray-200 px-8 pt-6 pb-8 mb-4 rounded-2xl">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Username"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
          </div>

          <div className="mb-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY ?? ""}
              onChange={handleCaptchaChange}
            />
          </div>
          
          <div className="flex items-center justify-between">
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              Delete Account
            </button>
          </div>
          {statusMessage && (
            <p className="text-sm pt-4 text-red-600">{statusMessage}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default DeleteAccount;
